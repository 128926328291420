<template>
  <v-container fluid class="px-0">
    <MyDialog ref="detailsModal" @onConfirm="deleteItem" />
    <products-modal
      ref="productsModal"
      :selectedData="selectedData"
      :isAdmin="true"
    />
    <MyDataTable
      title="دوره های موجود"
      subtitle="مدیریت دوره"
      addtitle="ایجاد دوره جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onDetailsClick(row)"
            >
              <v-icon small dark>mdi-apps</v-icon>
              مشاهده
            </v-btn>
          </template>
          <span dir="ltr">{{ "moholand.com/courses/" + row.slug }}</span>
        </v-tooltip>
        <v-btn
          class="mx-2"
          dark
          small
          color="purple"
          v-bind="attrs"
          v-on="on"
          @click="navigateToChapters(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          سرفصل ها
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.title }}</td>
        <td>
          {{
            row.user && row.user.first_name && row.user.last_name
              ? row.user.first_name + " " + row.user.last_name
              : ""
          }}
        </td>
        <td>
          {{ row.price ? Intl.NumberFormat().format(row.price) : 0 }}
        </td>
        <td>{{ row.capacity }}</td>
        <td class="text-center">
          <v-chip
            v-if="row.status == 0"
            color="yellow darken-3"
            dark
            class="rounded"
            small
          >
            در حال بررسی
          </v-chip>
          <v-chip
            v-else-if="row.status == 1"
            color="success"
            dark
            class="rounded"
            small
          >
            تایید شده
          </v-chip>
          <v-chip v-else color="red" dark class="rounded" small>
            رد شده
          </v-chip>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import ProductsModal from "@/components/Panel/ProductsModal";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    ProductsModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/courses/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["user", "instructor"],
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/coursemanager/edit/" + item.slug,
        query: this.$route.query || {},
      });
    },
    navigateToChapters(item) {
      this.$router.push({
        path: "/chaptersmanager/index/" + item.id,
      });
    },
    deleteItem(item) {
      MyAxios.delete(`/courses/${item.slug}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    onDetailsClick(item) {
      // this.selectedData = item;
      // this.$refs.productsModal.dialog = true;
      window.open("/courses/" + item.slug, "_blank");
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/courses/index",
      pathParams: {
        with: "user",
      },
      headers: [
        {
          text: "نام دوره",
          align: "start",
          value: "name",
        },
        { text: "برای", value: "user_id" },
        { text: "قیمت", value: "price" },
        { text: "گنجایش", value: "capacity" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
